<template>
  <PasswordForm />
</template>

<script>
import PasswordForm from 'organisms/Forms/Password/Passwordform'

export default {
  name: 'ChangePasswordTemplate',
  components: {
    PasswordForm
  }
}
</script>

<style lang="scss">
</style>
